import React, {useState} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage2Light from "./pages/Homepage2Light";
import BlogDetails from "./pages/BlogDetails";
import NotFound from "./pages/NotFound";
import "./App.scss";

function App() {

    const [french, setToggleFrench] = useState(false);

    const frenchToggler = (e) => {
        e.preventDefault();
        if (french) {
            e.target.src = "images/france-icon.png"
        } else {
            e.target.src = "images/english-icon.png"
        }
        setToggleFrench(!french);
    };

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Homepage2Light language={french} languageToggler={frenchToggler} />
        </Route>
        {/* <Route path="/blogs/blog-details/:id/:title" component={BlogDetails} /> */}
        <Route path="*">
            <NotFound language={french}/>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
