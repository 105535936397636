import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function Timeline({ education }) {
  const { years, title, content, company } = education; // Ajout de company dans la déstructuration

  return (
      <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
        <div className="timeline-container">
          <div className="content">
            <span className="time">{years}</span>
            <h3 className="title">{title}</h3>
            { company && <h4 className="company">{company}</h4> } {/* Afficher le nom de l'entreprise */}
            <p>{content}</p>
          </div>
        </div>
      </ScrollAnimation>
  );
}

export default Timeline;
