import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

function Services({language}) {

  const servicesData = [
    {
      id: 1,
      icon: "images/fullstack.png",
      title: language ? "Ingénieur Full Stack" : "Full Stack Engineer",
      content: language ?
          "Expert en développement Full Stack, j'associe créativité et compétences techniques pour réaliser des projets innovants et performants." :
          "As a Full Stack development expert, I blend creativity and technical skills to create innovative and high-performing projects.",
      color: "#6C6CE5",
      contentColor: "light",
    },
    {
      id: 2,
      icon: "images/cloud-computing.png",
      title: language ? "DevOps et Cloud" : "DevOps and Cloud",
      content: language ?
          "Passionné par le DevOps et Cloud, je me consacre à approfondir mes connaissances et à intégrer ces compétences dans mes projets de développement." :
          "Passionate about DevOps and Cloud, I am dedicated to deepening my knowledge and integrating these skills into my development projects.",
      color: "#26e9f3",
      contentColor: "dark",
    },
    {
      id: 3,
      icon: "images/teamspirit.png",
      title: language ? "Esprit d'équipe" : "Team spirit",
      content: language ?
          "Enthousiaste du travail en équipe, je m'engage à apporter une approche collaborative à chaque projet, en intégrant les meilleures pratiques de développement." :
          "I adore teamwork, bringing a collaborative approach to every project, integrating best development practices.",
      color: "#3CB371",
      contentColor: "light",
    }
  ];

  return (
    <section id="services">
      <div className="container">
        <Pagetitle title= {language ? "Compétences" : "Skill Set"} />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            {language? "Des questions?" : "Any questions?"}{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              {language ? "Cliquez" : "Click"}
            </Link>{" "}
            {language ? "pour me contacter !" : "to contact me!"} 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
