import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

function Testimonials({language}) {

  const sliderData = [
    {
      id: 1,
      avatar: "images/avatar-3.png",
      userName: "Tsurumaru Kuninaga",
      subtitle: "Touken Ranbu",
      review:
          (language) ? "ははっ、今夜は驚きはなしだ。何やっても花火に負けてしまうからな" : "ははっ、今夜は驚きはなしだ。何やっても花火に負けてしまうからな",
    },
    {
      id: 2,
      avatar: "images/avatar-1.png",
      userName: "Atsuki Okano",
      subtitle: "Hajime-chan Ga Ichiban!",
      review:
          (language) ? '勇気を与えるのは私だ' : "勇気を与えるのは私だ",
    },
    {
      id: 3,
      avatar: "images/avatar-2.png",
      userName: "Machi Tokiwa",
      subtitle: "Hanayamata",
      review:
          (language) ? "誰も現実の幻想を欺くことはできません!" : "誰も現実の幻想を欺くことはできません!",
    },

  ];

  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title={(language) ? "Citations japonaises" : "Japanese quotes"} />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
