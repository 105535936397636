import React, { useState } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header2 from "../components/layouts/Header2";
import About from "../components/sections/About";
import Blogs from "../components/sections/Blogs";
import Brandlogos from "../components/sections/Brandlogos";
import Contact from "../components/sections/Contact";
import Experiences from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";
import Pricing from "../components/sections/Pricing";
import Services from "../components/sections/Services";
import Testimonials from "../components/sections/Testimonials";
import Works from "../components/sections/Works";

function Homepage2Light({language, languageToggler}) {
  document.body.classList.remove("dark");

  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleDark, setToggleDark] = useState(true);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  const lightToggler = (e) => {
    e.preventDefault();
    if (toggleDark) {
      e.target.src= "images/night-mode.png"
    } else {
      e.target.src = "images/night-mode2.png"
    }
    setToggleDark(!toggleDark);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content-2")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <Header2
        light={toggleDark}
        logoSource="/images/logo-b-dark.png"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
        lightToggler = {lightToggler}
        languageToggler = {languageToggler}
        language={language}
      />
      <main className={toggleMenu ? "content-2 open" : "content-2"}>
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection light={toggleDark} language={language} />
          </ReactCursorPosition>
        </Element>
        <Element name="section-about">
          <About language={language}/>
        </Element>
        <Element name="section-services">
          <Services language={language}/>
        </Element>
        <Element name="section-experiences">
          <Experiences language={language} />
        </Element>
        <Element name="section-works">
          <Works language={language}/>
        </Element>
        {/* <Element name="section-pricing">
          <Pricing />
        </Element> */}
        {/*<Element name="section-testimoninal">
          <Testimonials language={language} />
        </Element>*/}
        {/* <Element name="section-brandlogos">
          <Brandlogos />
        </Element> */}
        {/*
        <Element name="section-blogs">
          <Blogs />
        </Element>
        */}
        <Element name="section-contact">
          <Contact language={language} />
        </Element>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Homepage2Light;
