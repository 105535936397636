import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

function About({language}) {

  const aboutContent = {
    name: "Tolga",
    avatarImage: "/images/avatar.png",
    content: language ?
        "Je suis Tolga SAHIN, un jeune ingénieur web et logiciel full stack. Passionné par les nouvelles technologies, je me consacre au développement de solutions innovantes et à l'avancement de ma carrière dans le secteur technologique du jeu vidéo." :
        "I am Tolga SAHIN, a young full-stack web and software engineer. Passionate about new technologies, I am dedicated to developing innovative solutions and advancing my career in the tech and gaming industry."
  };


  const progressData = [
    {
      id: 1,
      title: language ? "Développement frontend" : "Frontend development",
      percantage: 70,
      progressColor: "#FFD15C",
    },
    {
      id: 2,
      title: language ? "DevOps & Cloud" : "DevOps & Cloud",
      percantage: 80,
      progressColor: "#FF4C60",
    },
    {
      id: 3,
      title: language ? "Développement backend" : "Backend development",
      percantage: 90,
      progressColor: "#6C6CE5",
    },
  ];

  const counterData = [
    {
      id: 1,
      title: language ? "Projets estimés (public et privé)" : "Estimated projects (public and private)",
      count: 113,
      icon: "icon-fire",
    },
    {
      id: 2,
      title: language ? "Projets web" : "Web projects",
      count: 52,
      icon: "icon-cup",
    },
    {
      id: 3,
      title: language ? "Projets logiciel" : "Software projects",
      count: 61,
      icon: "icon-people",
    },
    {
      id: 4,
      title: language ? "Honneurs" : "Honors",
      count: 10,
      icon: "icon-badge",
    },
  ];


  return (
    <section id="about">
      <div className="container">
        <Pagetitle title={language ? "À Propos de Moi" : "About Me"} />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a href="/cv/CV_Tolga_SAHIN.pdf" target={"_blank"} className="btn btn-default">
                      {(language) ? "Télécharger le CV" : "Download CV"}
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
