import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

function Works({language}) {
  const filters = [
    {
      id: 1,
      text: language ? "Tout" : "Everything",
    },
    {
      id: 2,
      text: language ? "Web" : "Web",
    },
    {
      id: 3,
      text: language ? "App" : "App",
    },
    {
      id: 4,
      text: language ? "Autres" : "Others",
    },
  ];

  const allData = [
    {
      id: 1,
      title: (language) ? "Nostorm V2 : Jeu vidéo MMORPG en 3D" : "Nostorm V2 : MMORPG Video game in 3D",
      category: "app",
      image: "images/works/01.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 2,
      title: (language) ? "Nostorm V2 : Application web" : "Nostorm V2 : Web application",
      category: "web",
      image: "images/works/nostormv2.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 3,
      title: language ? "Ma Ville Dans Ma Poche : Application mobile/web multiservices pour les villes" : "My City In My Pocket: Multiservice mobile/web application for cities",
      category: "web",
      image: "images/works/phone_mavdmp.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 4,
      title: language ? "Facilities Now : Application mobile/web multiservices pour les entreprises" : "Facilities Now: Multiservice mobile/web application for businesses",
      category: "web",
      image: "images/works/phone_fnow.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 5,
      title: language ? "Projet de migration de Facilities Now vers Directus" : "Facilities Now Migration Project to Directus",
      category: "web",
      image: "images/works/migration.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 6,
      title: language ? "Développement et infrastructure sur le CMS Headless Directus" : "Development and Infrastructure on Headless CMS Directus",
      category: "web",
      image: "images/works/directus.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 7,
      title: language ? "Développement sur le CMS Headless Strapi" : "Development on Headless CMS Strapi",
      category: "web",
      image: "images/works/strapi.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 8,
      title: (language) ? "CityTraffic : Logiciel Java et web de Big Data" : "CityTraffic : Java and web software of Big Data",
      category: "web",
      image: "images/works/citytraffic.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 9,
      title: (language) ? "Claper : movie search engine by image or video using AI" : "Claper : moteur de recherche par image ou vidéo de film avec une IA",
      category: "app",
      image: "images/works/claper.jpg",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 10,
      title: (language) ? "Whatanime - Application web pour effectuer des quiz générables sur les animés" : "Whatanime - Web application for generating quizzes about anime",
      category: "web",
      image: "images/works/10.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 11,
      title: (language) ? "Pistâche: Logiciel de Conduite de Projet" : "Pistâche : Project Management Software",
      category: "app",
      image: "images/works/kanban.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 12,
      title: (language) ? "TF-Idf moteur de recherche performant" : "TF-Idf optimized search engine",
      category: "others",
      image: "images/works/tf-idf.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 13,
      title: (language) ? "Solveur de labyrinthe" : "Maze Solver",
      category: "app",
      image: "images/works/06.jpg",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 14,
      title: (language) ? "Application de météo" : "Weather application",
      category: "app",
      image: "images/works/meteo.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 15,
      title: language ? "Formation C pour les étudiants en informatique" : "C Programming Course for Computer Science Students",
      category: "others",
      image: "images/works/formation_c.png",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 16,
      title: (language) ? "Conduite de projet en équipe" : "Team project management",
      category: "others",
      image: "images/works/04.jpg",
      link: "https://github.com/tolgatr61",
    },
    {
      id: 17,
      title: (language) ? "Jeu Tetris" : "Tetris game",
      category: "app",
      image: "images/works/tetris.png",
      link: "https://github.com/tolgatr61",
    },
  ];


  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState(filters[0].text.toLowerCase());
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setVisibleItems(getFilteredItems(allData, activeFilter, dataVisibleCount));
  }, [activeFilter, dataVisibleCount, allData]);

  useEffect(() => {
    // Mettre à jour les filtres et les éléments visibles en fonction de la langue
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getFilteredItems(allData, activeFilter, dataVisibleCount));
  }, [language]); // Ajoutez language comme dépendance

  const getFilteredItems = (data, filter, count) => {
    if (filter === filters[0].text.toLowerCase()) {
      return data.slice(0, count);
    } else {
      return data
          .filter(item => item.category === filter)
          .slice(0, count);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (tempCount >= allData.length) {
      tempCount = allData.length;
    }
    setDataVisibleCount(tempCount);
  };

  return (
      <section id="works">
        <div className="container">
          <Pagetitle title={language ? "Projets récents" : "Recent Works"} />
          <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
          >
            <ul className="portfolio-filter list-inline">
              {filters.map((filter) => (
                  <li className="list-inline-item" key={filter.id}>
                    <button
                        onClick={handleChange}
                        className={
                          filter.text.toLowerCase() === activeFilter
                              ? "text-capitalize current"
                              : "text-capitalize"
                        }
                    >
                      {filter.text}
                    </button>
                  </li>
              ))}
            </ul>
          </ScrollAnimation>

          <div className="row portfolio-wrapper">
            {visibleItems.map((item) => (
                <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
                  <Portfolio portfolio={item} />
                </div>
            ))}
          </div>

          <div className="load-more text-center mt-4">
            <button
                className="btn btn-default"
                onClick={handleLoadmore}
                disabled={dataVisibleCount >= allData.length ? "disabled" : null}
            >
              {dataVisibleCount >= allData.length ? (
                  language ? "Limite atteinte" : "Limit reached"
              ) : (
                  <span>
                <i className="fas fa-spinner"></i> {language ? "Voir plus" : "See more"}
              </span>
              )}
            </button>
          </div>
        </div>
      </section>
  );
}

export default Works;
