import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

function Experiences({language}) {

  const educationData = [
    {
      id: 1,
      title: language ? "Master Informatique Génie Logiciel" : "Master of Software Engineering",
      years: "2021 - 2023",
      content: language ?
          "Université de Bordeaux | Major de promotion - Mention très bien" :
          "University of Bordeaux | Top of class - With highest honors",
    },
    {
      id: 2,
      title: language ? "Licence Informatique" : "Bachelor of Computer Science",
      years: "2018 - 2021",
      content: language ?
          "Université de Caen Normandie | Mention très bien" :
          "University of Caen Normandy | With highest honors",
    },
    {
      id: 3,
      title: language ? "Baccalauréat Scientifique" : "Scientific Baccalaureate",
      years: "2017 - 2018",
      content: language ?
          "Lycée Marguerite de Navarre à Alençon" :
          "Marguerite de Navarre High School, Alençon",
    },
  ];


  const experienceData = [
    {
      id: 1,
      title: language ? "Ingénieur Full Stack" : "Full Stack Engineer",
      company: "Orange Business Bordeaux, France",
      years: language ? "2023 - 6 Mois" : "2023 - 6 Months",
      content: language ?
          "Remplacement de la brique CMS headless existante par Directus après une étude approfondie. Création d'un projet Node.js pour lire et migrer les modèles de données et données de l'ancien CMS. Développement d'applications en Vue.js sur Directus, intégration de Keycloak pour la gestion des identités et des accès, et construction d'une infrastructure complète utilisant Azure, Kong, Kubernetes et Docker." :
          "Replaced the existing headless CMS component with Directus following an extensive study. Developed a Node.js project to read and migrate data models and data from the old CMS. Developed applications in Vue.js on Directus, integrated Keycloak for identity and access management, and built a complete infrastructure using Azure, Kong, Kubernetes, and Docker."
    },
    {
      id: 2,
      title: language ? "Développeur Full Stack" : "Full Stack Developer",
      company: "GREYC Lab Caen, France",
      years: language ? "2021 - 6 Mois" : "2021 - 6 Month",
      content: language ?
          "Refonte et optimisation d'une plateforme web pour l'indexation des publications scientifiques. Migration technologique vers React.js (frontend) et Node.js avec TypeScript et Express.js (backend). Ajout de profils pour chercheurs et visualisations interactives en D3.js. Amélioration de la sécurité, conformité RGPD, déploiement via Docker avec Nginx et Apache, tests avec Jest et Selenium." :
          "Redesigned and optimized a web platform for indexing scientific publications. Technological migration to React.js (frontend) and Node.js with TypeScript and Express.js (backend). Added researcher profiles and interactive visualizations using D3.js. Enhanced security, GDPR compliance, deployment through Docker with Nginx and Apache, tested with Jest and Selenium."
    }
  ];


  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title={(language) ? "Expérience" : "Experience"} />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
